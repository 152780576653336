
import { getArticleList, openListAnswer } from '@/api/answer'
import { listCaseList } from '@/api/industry'
import { listPolicyList } from '@/api/policy'
import {
  getTemplateListNoToken,
  updateClick
} from '@/api/template'
import {
  getDetailsByIdNoToken,
  getDetailById
} from '@/api/product'
import recommend from '../policy/recommend'
import CONFIG from '@/othComponents/codeTemplate/config'
import HomeMsg from '@/othComponents/homeCode/template/components/HomeMsg'
import Cookies from 'js-cookie'
import eventBus from '@/utils/event-bus'
const cmsNewsUrl = process.env.CMS_NEWS_URL

export default {
  name : 'SearchHome',
  components : {
    HomeMsg,
    recommend
  },
  layout : 'base',
  scrollToTop : true,
  async asyncData( context ) {
    let hotData = null
    let searchData = null
    try {
      const p3 = {
        pageNum : 3,
        pageSize : 10,
        category : context.query.category == '全部' ? '' : context.query.category
      }
      const resC = await listPolicyList( p3 )
      hotData = resC.rows

      const resA = await openListAnswer( {
        pageNum : 1,
        pageSize : 10,
        title : context.query.v
      } )
      resA.rows.map( i => {
        if ( parseInt( i.createBy ) ) {
          i.nickName = i.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
        } else {
          i.nickName = i.createBy
        }
      } )
      searchData = resA.rows
    } catch {
      // ignore error
    }
    return { hotData, searchData }
  },
  data() {
    const template = {
      name : '',
      config : {
        getLocation : false,
        getWeixinUserInfo : false
      },
      items : [],
      productId : null,
      submitLoading : false
    }
    for ( const key of Object.keys( CONFIG ) ) {
      const config = CONFIG[key]
      if ( config.fixedSort || config.must ) {
        template.items.push( this.newItem( key ) )
      }
    }
    template.items.sort(
      ( a, b ) =>
        ( CONFIG[a.type].fixedSort || 100 ) - ( CONFIG[b.type].fixedSort || 100 )
    )
    return {
      cmsNewsUrl,
      loading : false,
      template : null,
      showModel : false,
      qrText : '',
      searVal : '',
      searchData : [],
      icon_1 : require( '@/assets/images/community/icon_reply.png' ),
      iconv : require( '@/assets/images/community/icon_3.png' ),
      icona : require( '@/assets/images/community/icon_4.png' ),
      icon_e : require( '@/assets/images/ey_icon.png' ),
      icon_v : require( '@/assets/images/view_icon.png' ),
      defaultImg : require( '@/assets/images/img_default.png' ),
      noDataImg : require( '@/assets/images/noData.png' ),
      typeList : [
        {
          name : '社区问答'
        },
        {
          name : '经验文章'
        },
        {
          name : '行业案例'
        },
        {
          name : '产品模板'
        },
        {
          name : '资源中心'
        }
      ],
      actName : '社区问答',
      types : [
        {
          name : '产品建议',
          value : '0',
          color : '#4E9F2F'
        },
        {
          name : '操作使用',
          value : '1',
          color : '#16537D'
        },
        {
          name : '交流讨论',
          value : '2',
          color : '#C6941A'
        },
        {
          name : 'Bug反馈',
          value : '3',
          color : '#8C8573'
        }
      ],
      hotData : []
    }
  },
  computed : {},

  watch : {
    $route( to, from ) {
      this.searVal = to.query.v
      this.getListAnswer()
    }
  },

  created() {
    this.searVal = this.$route.query.v
  },
  mounted() {
    Cookies.set( 'activeIndex', '6' )
    this.getListAnswer()
    this.handleScroll()
    eventBus.$emit( 'getPageData', true )
  },
  destroyed() {
    this.actName = ''
  },
  methods : {
    handleScroll() {
      if ( process.browser ) {
        window.scrollBy( 0, -100 )
      }
    },
    handleSearch() {
      this.searchData = []
      this.loading = true
      if ( this.actName == '社区问答' ) {
        this.getListAnswer()
      } else if ( this.actName == '经验文章' ) {
        this.getArticleList()
      } else if ( this.actName == '行业案例' ) {
        this.getCaseList()
      } else if ( this.actName == '产品模板' ) {
        this.getListNoToken()
      } else if ( this.actName == '资源中心' ) {
        this.getPolicyList()
      }
    },
    selectType( v ) {
      this.actName = v
      this.searchData = []
      this.loading = true
      if ( v == '社区问答' ) {
        this.getListAnswer()
      } else if ( v == '经验文章' ) {
        this.getArticleList()
      } else if ( v == '行业案例' ) {
        this.getCaseList()
      } else if ( v == '产品模板' ) {
        this.getListNoToken()
      } else if ( v == '资源中心' ) {
        this.getPolicyList()
      }
    },
    loadMore() {
      if ( this.actName == '社区问答' ) {
        this.$router.push( { path : '/community' } )
        Cookies.set( 'activeIndex', '6' )
        eventBus.$emit( 'activeIndex', '6' )
      } else if ( this.actName == '经验文章' ) {
        this.$router.push( { path : '/community' } )
        Cookies.set( 'activeIndex', '6' )
        eventBus.$emit( 'activeIndex', '6' )
      } else if ( this.actName == '行业案例' ) {
        this.$router.push( { path : '/industryCase' } )
        Cookies.set( 'activeIndex', '7' )
        eventBus.$emit( 'activeIndex', '7' )
      } else if ( this.actName == '产品模板' ) {
        this.$router.push( { path : '/templateLibrary' } )
        Cookies.set( 'activeIndex', '3' )
        eventBus.$emit( 'activeIndex', '3' )
      } else if ( this.actName == '资源中心' ) {
        window.location = cmsNewsUrl + '/article/news/list?keyword=' + this.searchValue
      }
    },
    jumpDetail( t, item ) {
      if ( t == 1 ) {
        this.$router.push( { name : 'community', query : { answer : JSON.stringify( item ) }} )
      } else if ( t == 2 ) {
        // this.$router.push({ name: 'community', query: { articleId: item.id } })
        this.$router.push( { path : '/communityAsk', query : { id : item.id, from : 'front' }} )
      } else if ( t == 3 ) {
        this.$router.push( { name : 'IndustryCaseDetails', query : { id : item.id, industry : item.industry }} )
      }
    },
    getListAnswer() {
      const p = {
        pageNum : 1,
        pageSize : 10,
        title : this.searVal
      }
      openListAnswer( p ).then( res => {
        res.rows.map( i => {
          if ( parseInt( i.createBy ) ) {
            i.nickName = i.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
          } else {
            i.nickName = i.createBy
          }
        } )
        this.searchData = res.rows
        this.loading = false
      } )
    },
    getArticleList() {
      const p = {
        pageNum : 1,
        pageSize : 10,
        query : this.searVal
      }
      getArticleList( p ).then( res => {
        res.rows.map( i => {
          if ( parseInt( i.createUserName ) ) {
            i.createUserName = i.createUserName.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
          }
        } )
        this.searchData = res.rows
        this.loading = false
      } )
    },
    getCaseList() {
      this.loading = true
      const p = {
        pageNum : 1,
        pageSize : 10,
        title : this.searVal
      }
      listCaseList( p ).then( res => {
        this.searchData = res.rows
        this.loading = false
      } )
    },
    getPolicyList() {
      const p = {
        pageNum : 1,
        pageSize : 10,
        query : this.searVal
      }
      listPolicyList( p ).then( res => {
        res.rows.map( i => {
          if ( i.time.indexOf( 'T' ) > -1 ) {
            i.time = i.time.split( 'T' )[0]
          }
          if ( i.time.indexOf( ' ' ) > -1 ) {
            i.time = i.time.split( ' ' )[0]
          }
          i.title = i.title.replace( /<em>/g, '' )
          i.title = i.title.replace( /<\/em>/g, '' )
          i.content = i.content.replace( /<em>/g, '' )
          i.content = i.content.replace( /<\/em>/g, '' )
          i.content = i.content.replace( /&nbsp;/g, '' )
          i.content = i.content.substr( 0, 300 )
        } )
        this.searchData = res.rows
        this.loading = false
      } )
    },
    getListNoToken() {
      const p = {
        name : this.searVal,
        reviewStatus : 1
      }
      getTemplateListNoToken( p ).then( res => {
        this.searchData = res.rows.splice( 0, 10 )
        this.loading = false
      } )
    },
    showTemplate( i ) {
      this.getTempDetail( i.id )
      this.qrText = `${window.location.origin}/w/c?templateId=${i.id}`
      this.showModel = true
      updateClick( i.id )
    },
    getTempDetail( templateId ) {
      let template
      if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
        getDetailById( templateId ).then( ( response ) => {
          template = response.data
          if ( template.config && typeof template.config === 'string' ) {
            template.config = JSON.parse( template.config )
          }
          const items = []
          for ( const raw of template.items ) {
            if ( raw && CONFIG[raw.type] ) {
              if ( typeof raw.value === 'string' ) {
                raw.value = JSON.parse( raw.value )
              }
              const defaultValue = CONFIG[raw.type].defaultValue
              if ( !( defaultValue instanceof Array ) ) {
                for ( const key of Object.keys( defaultValue ) ) {
                  if ( raw.value[key] === undefined ) {
                    raw.value[key] = defaultValue[key]
                  }
                }
              }
              items.push( raw )
            }
          }
          this.template = template
        } )
      } else {
        getDetailsByIdNoToken( templateId ).then( ( response ) => {
          template = response.data
          if ( template.config && typeof template.config === 'string' ) {
            template.config = JSON.parse( template.config )
          }
          const items = []
          for ( const raw of template.items ) {
            if ( raw && CONFIG[raw.type] ) {
              if ( typeof raw.value === 'string' ) {
                raw.value = JSON.parse( raw.value )
              }
              const defaultValue = CONFIG[raw.type].defaultValue
              if ( !( defaultValue instanceof Array ) ) {
                for ( const key of Object.keys( defaultValue ) ) {
                  if ( raw.value[key] === undefined ) {
                    raw.value[key] = defaultValue[key]
                  }
                }
              }
              items.push( raw )
            }
          }
          this.template = template
        } )
      }
    },
    addNewTemplate( item ) {
      console.log( '>>>', item )
      if ( item ) {
        this.$router.push( { path : '/homeCodeMain', query : { id : item.id, type : 'add' }} )
      } else {
        this.$router.push( '/homeCodeMain' )
      }
    },
    closedModel() {
      this.template = null
      this.showModel = false
    }
  }
}

