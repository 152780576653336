
import eventBus from '@/utils/event-bus'
import { listNewsLabel, listPolicyList } from '@/api/policy'
import {sendAction} from "@/plugins/buriedPoint";
const cmsNewsUrl = process.env.CMS_NEWS_URL

export default {
  name : 'Recommend',
  components : {
  },
  layout : 'base',
  props : ['isDetail', 'showHot'],
  async asyncData( context ) {
    const time = context.$dayjs( new Date() ).subtract( 2, 'year' ).format()
    let moodData = null
    let hotPointData = null
    let hotData = null
    let relevantData = null
    let labels = null
    try {
      const p = {
        pageNum : 3,
        pageSize : 10,
        category : context.query.category == '全部' ? '' : context.query.category,
        timeStart : time,
        orderByClickDesc : true
      }
      const resA = await listPolicyList( p )
      moodData = resA.rows
      const p2 = {
        pageNum : 3,
        pageSize : 6,
        category : context.query.category == '全部' ? '' : context.query.category
      }
      const resB = await listPolicyList( p2 )
      hotPointData = resB.rows

      const p3 = {
        pageNum : 3,
        pageSize : 10,
        category : context.query.category == '全部' ? '' : context.query.category,
        orderBy : '-Click'
      }
      const resC = await listPolicyList( p3 )
      hotData = resC.rows

      const p4 = {
        pageNum : 3,
        pageSize : 6,
        category : context.query.category == '全部' ? '' : context.query.category
      }
      const resD = await listPolicyList( p4 )
      relevantData = resD.rows

      const resE = await listNewsLabel( )
      labels = resE.rows
    } catch {
      // ignore error
    }
    return { moodData, hotPointData, hotData, relevantData, labels }
  },
  data() {
    return {
      cmsNewsUrl,
      isDetails : false,
      isShowHot : false,
      defaultImg : require( '@/assets/images/img_default.png' ),
      viewIcon : require( '@/assets/images/yan.png' ),
      ca : '',
      la : '',
      moodData : null,
      hotPointData : null,
      hotData : null,
      relevantData : null,
      startH : 2,
      startR : 2,
      noDataImg : require( '@/assets/images/noData.png' ),
      mloading : false,
      ploading : false,
      hloading : false,
      rloading : false,
      labels : [],
      myColors : ['#3CC5AA', '#2996BF', '#FF9702', '#FF4F02', '#D24000', '#29BFAC', '#FF4F02', '#FFC067', '#9ABF29', '#f47920', '#fedcbd',
        '#80752c', '#87843b', '#fab27b', '#585eaa', '#afb4db', '#78a355', '#bb505d', '#826858', '#472d56'],
      rotate : {
        from : 0,
        to : 0
      },
      fontSize : [4, 18]
    }
  },

  computed : {},

  created() {
    this.isDetails = this.$props.isDetail
    this.isShowHot = this.$props.showHot
  },
  mounted() {
    // 人气
    // this.getMoodList()
    // 热点
    // this.getHotPointList()
    // 热门
    // this.getHotList()
    // 相关
    // this.getRelevant()
    eventBus.$on( 'recommend', ( k ) => {
      if ( k && k != undefined ) {
        if ( k.type == 'la' ) {
          this.la = k.val
        }
        if ( k.type == 'ca' ) {
          this.ca = k.val
        }
      }
      if ( this.isDetails ) {
        this.getHotList()
        this.getRelevant()
      } else {
        this.getMoodList()
        this.getHotPointList()
      }
    } )
    if ( this.isShowHot ) {
      this.getHotList()
    }
    this.listNewsLabel()
    eventBus.$emit( 'getPageData', true )
  },
  beforeDestroy() {
    eventBus.$off( 'recommend' )
  },
  methods : {
    actionRecord(url,name,el){
      sendAction({
        operType:'click',
        operDescription:'点击【'+ name +'】网址跳转：' + url,
        page :'智能码-搜索页'+el,
        triggerType :'click'
      })
    },
    getMoodList() {
      this.mloading = true
      const time = this.$dayjs( new Date() ).subtract( 2, 'year' ).format()
      const p = {
        pageNum : 1,
        pageSize : 10,
        category : this.ca == '全部' ? '' : this.ca,
        // label: this.la == '全部' ? '' : this.la,
        timeStart : time,
        orderByClickDesc : true
      }
      listPolicyList( p ).then( res => {
        res.rows.map( i => {
          if ( i.click >= 10000 && i.click < 20000 ) {
            i.click = '1万+'
          } else if ( i.click > 20000 && i.click < 30000 ) {
            i.click = '2万+'
          } else if ( i.click > 30000 && i.click < 40000 ) {
            i.click = '3万+'
          } else if ( i.click > 40000 ) {
            i.click = '4万+'
          }
          i.time = this.$dayjs( i.time ).format( 'YYYY-MM-DD' )
        } )
        this.moodData = res.rows
        this.mloading = false
      } )
    },
    getHotPointList() {
      this.ploading = true
      const p = {
        pageNum : 2,
        pageSize : 6,
        category : this.ca == '全部' ? '' : this.ca
        // label: this.la == '全部' ? '' : this.la,
      }
      listPolicyList( p ).then( res => {
        res.rows.map( i => {
          if ( i.click >= 10000 && i.click < 20000 ) {
            i.click = '1万+'
          } else if ( i.click > 20000 && i.click < 30000 ) {
            i.click = '2万+'
          } else if ( i.click > 30000 && i.click < 40000 ) {
            i.click = '3万+'
          } else if ( i.click > 40000 ) {
            i.click = '4万+'
          }
          i.time = this.$dayjs( i.time ).format( 'YYYY-MM-DD' )
          if ( i.websiteName ) {
            if ( i.websiteName.length > 10 ) {
              i.websiteName = i.websiteName.slice( 0, 5 ) + '...'
            }
          }
        } )
        this.hotPointData = res.rows
        this.ploading = false
      } )
    },
    getHotList() {
      this.hloading = true
      const p = {
        pageNum : 2,
        pageSize : 10,
        category : this.ca == '全部' ? '' : this.ca,
        orderBy : '-Click'
      }
      listPolicyList( p ).then( res => {
        res.rows.map( i => {
          if ( i.click >= 10000 && i.click < 20000 ) {
            i.click = '1万+'
          } else if ( i.click > 20000 && i.click < 30000 ) {
            i.click = '2万+'
          } else if ( i.click > 30000 && i.click < 40000 ) {
            i.click = '3万+'
          } else if ( i.click > 40000 ) {
            i.click = '4万+'
          }
        } )
        this.hotData = res.rows
        this.hloading = false
      } )
    },
    getRelevant() {
      this.rloading = true
      const p = {
        pageNum : 2,
        pageSize : 6,
        category : this.ca == '全部' ? '' : this.ca
        // label: this.la == '全部' ? '' : this.la,
      }
      listPolicyList( p ).then( res => {
        this.relevantData = res.rows
        this.rloading = false
      } )
    },
    // topClicked() {
    //   topClicked({ count: 10, start: this.start }).then(res => {
    //     this.hotData = res.data
    //     this.hotLoading = false
    //   })
    // },
    // getRelevant() {
    //   listPolicyList(this.queryParams).then(res => {
    //     this.relevantData = res.rows
    //     this.rLoading = false
    //   })
    // },
    changeHData() {
      this.startH = this.startH + 1
      this.getHotList()
    },
    changeRData() {
      this.startR = this.startR + 1
      this.getRelevant()
    },
    listNewsLabel() {
      const p = {
        dictType : 'new_label',
        status : 0
      }
      listNewsLabel( p ).then( res => {
        res.rows.map( i => {
          i.name = i.dictLabel
          i.value = i.dictValue
        } )
        this.labels = res.rows
      } )
    },
    wordClick( v, k ) {
      window.open( cmsNewsUrl + '/article/news/list' )
    }
  }
}

